import { Events, SuccessState, Message, Data } from './Messages';
import { SpiConfig, TransactionOptions } from './SpiModels';

export class SettleRequest {
    Config: SpiConfig
    Options: TransactionOptions

    readonly Id: string

    constructor(id: string) {
        this.Id = id;
        this.Config = new SpiConfig();
        this.Options = new TransactionOptions();
    }

    ToMessage() {
        const data = {} as Data;

        this.Config.EnabledPrintMerchantCopy = true;
        this.Config.EnabledPromptForCustomerCopyOnEftpos = false;
        this.Config.EnabledSignatureFlowOnEftpos = false;
        this.Config.AddReceiptConfig(data);
        this.Options.AddOptions(data);
        return new Message<Data>(this.Id, Events.SettleRequest, data, true);
    }
}

export interface SettlementResponseData extends Data {
    accumulated_settle_by_acquirer_count?: string
    accumulated_settle_by_acquirer_value?: string
    accumulated_total_count?: string
    accumulated_total_value?: string
    bank_date: string
    bank_time: string
    host_response_code: string
    host_response_text?: string
    merchant_acquirer: string
    merchant_id: string
    merchant_receipt?: string
    transaction_range?: string
    terminal_id?: string
    merchant_receipt_printed?: boolean
    settlement_period_start_time: string
    settlement_period_end_time: string
    settlement_period_start_date: string
    settlement_period_end_date: string
    settlement_triggered_time: string
    settlement_triggered_date: string
    schemes: Scheme[]
}

interface Scheme {
    scheme_name: string // VISA | MASTERCARD
    total_count: string
    total_value: string
    settle_by_acquirer: string
}

export class Settlement {
    RequestId: string
    Success: boolean

    private readonly _m: Message<SettlementResponseData>

    constructor(m: Message<SettlementResponseData>) {
        this.RequestId = m.Id;
        this._m = m;
        this.Success = m.GetSuccessState() == SuccessState.Success;
    }

    GetSettleByAcquirerCount() {
        return this._m.Data.accumulated_settle_by_acquirer_count;
    }

    GetSettleByAcquirerValue() {
        return this._m.Data.accumulated_settle_by_acquirer_value;
    }

    GetTotalCount() {
        return this._m.Data.accumulated_total_count;
    }

    GetTotalValue() {
        return this._m.Data.accumulated_total_value;
    }

    GetPeriodStartTime() {
        const timeStr = this._m.Data.settlement_period_start_time; // "05:00"
        const dateStr = this._m.Data.settlement_period_start_date; // "05Oct17"
        return Message.ParseBankDateTimeStr(dateStr, timeStr);
    }

    GetPeriodEndTime() {
        const timeStr = this._m.Data.settlement_period_end_time; // "05:00"
        const dateStr = this._m.Data.settlement_period_end_date; // "05Oct17"
        return Message.ParseBankDateTimeStr(dateStr, timeStr);
    }

    GetTriggeredTime() {
        const timeStr = this._m.Data.settlement_triggered_time; // "05:00:45"
        const dateStr = this._m.Data.settlement_triggered_date; // "05Oct17"
        return Message.ParseBankDateTimeStr(dateStr, timeStr);
    }

    GetResponseText() {
        return this._m.Data.host_response_text;
    }

    GetReceipt() {
        return this._m.Data.merchant_receipt;
    }

    GetTransactionRange() {
        return this._m.Data.transaction_range;
    }

    GetTerminalId() {
        return this._m.Data.terminal_id;
    }

    WasMerchantReceiptPrinted() {
        return this._m.Data.merchant_receipt_printed;
    }

    GetSchemeSettlementEntries() {
        const schemes = this._m.Data.schemes;
        if (!schemes) return [];

        return schemes.map((scheme: Scheme) => {
            return new SchemeSettlementEntry(scheme);
        });
    }
}

export class SchemeSettlementEntry {
    SchemeName: string
    SettleByAcquirer: boolean
    TotalCount: number
    TotalValue: number

    // SchemeSettlementEntry(string schemeName, bool settleByAcquirer, int totalCount, int totalValue)
    // SchemeSettlementEntry(Object schemeObj)
    constructor(...args: [Scheme] | [string, boolean, number, number]) {
        if (args.length === 1) {
            this.SchemeName = args[0].scheme_name;
            this.SettleByAcquirer = args[0].settle_by_acquirer.toLowerCase() == "yes";
            this.TotalValue = parseInt(args[0].total_value, 10);
            this.TotalCount = parseInt(args[0].total_count, 10);
        } else if (args.length === 4) {
            this.SchemeName = args[0];
            this.SettleByAcquirer = args[1];
            this.TotalCount = args[2];
            this.TotalValue = args[3];
        }
    }

    ToString() {
        return `SchemeName: ${this.SchemeName}, SettleByAcquirer: ${this.SettleByAcquirer}, TotalCount: ${this.TotalCount}, TotalValue: ${this.TotalValue}`;
    }
}

export class SettlementEnquiryRequest {
    Id: string
    Config: SpiConfig
    Options: TransactionOptions

    constructor(id: string) {
        this.Id = id;
        this.Config = new SpiConfig();
        this.Options = new TransactionOptions();
    }

    ToMessage() {
        const data = {} as Data;

        this.Config.EnabledPrintMerchantCopy = true;
        this.Config.EnabledPromptForCustomerCopyOnEftpos = false;
        this.Config.EnabledSignatureFlowOnEftpos = false;
        this.Config.AddReceiptConfig(data);
        this.Options.AddOptions(data);

        return new Message<Data>(this.Id, Events.SettlementEnquiryRequest, data, true);
    }
}
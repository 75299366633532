import { Nullable } from "../types";

export class DeviceAddressStatus {
  ip: Nullable<string> = null;
  fqdn: Nullable<string> = null;
  LastUpdated: Nullable<string> = null;

  UseSecureWebSockets: boolean;
  DeviceAddressResponseCode: Nullable<string> = null;
  ResponseStatusDescription: Nullable<string> = null;
  ResponseMessage: Nullable<string> = null;

  get Address() {
    if (this.UseSecureWebSockets) {
      return this.fqdn;
    } else {
      return this.ip;
    }
  }

  set Address(address) {
    if (this.UseSecureWebSockets) {
      this.fqdn = address;
    } else {
      this.ip = address;
    }
  }

  constructor(useSecureWebSockets: boolean) {
    this.UseSecureWebSockets = useSecureWebSockets;
  }
}

export const DeviceAddressResponseCode = {
  SUCCESS: "SUCCESS",
  INVALID_SERIAL_NUMBER: "INVALID_SERIAL_NUMBER",
  ADDRESS_NOT_CHANGED: "ADDRESS_NOT_CHANGED",
  SERIAL_NUMBER_NOT_CHANGED: "SERIAL_NUMBER_NOT_CHANGED",
  DEVICE_SERVICE_ERROR: "DEVICE_SERVICE_ERROR",
};

export const HttpStatusCode = {
  NotFound: 404,
  OK: 200,
};

export class DeviceAddressService {
  static async RetrieveDeviceAddress(params: {
    serialNumber: string;
    tenantCode: string;
    isSecureConnection: boolean;
    isTestMode: boolean;
    apiKey?: string;
    environmentCode?: string;
  }): Promise<Response | unknown> {
    const {
      serialNumber,
      apiKey,
      tenantCode,
      isSecureConnection,
      isTestMode,
      environmentCode,
    } = params;
    const CONNECTION_TIMEOUT = 8000;
    const path = isSecureConnection ? "fqdn" : "ip";
    const envSuffix = environmentCode ? `-${environmentCode}` : isTestMode ? "-sb" : "";

    const deviceAddressUri = `https://device-address-api${envSuffix}.${tenantCode}.mspenv.io/v1/${serialNumber}/${path}`;

    return Promise.race([
      fetch(deviceAddressUri, {
        method: "GET",
        headers: {
          "ASM-MSP-DEVICE-ADDRESS-API-KEY": apiKey,
        },
      }),
      new Promise((_, reject) =>
        setTimeout(
          () =>
            reject(new Error("Timeout while trying to retrieve IP address")),
          CONNECTION_TIMEOUT
        )
      ),
    ]);
  }
}

import {Events, Message} from './Messages';
import {Crypto} from './Crypto';
import {Secrets} from './Secrets';

export class KeyRollingHelper {
    static PerformKeyRolling(krRequest, currentSecrets)
    {
        const m = new Message<any>(krRequest.Id, Events.KeyRollResponse, {"status": "confirmed"}, true);
        const newSecrets = new Secrets(Crypto.GenerateHash(currentSecrets.EncKey).toUpperCase(),Crypto.GenerateHash(currentSecrets.HmacKey).toUpperCase());
        return new KeyRollingResult(m, newSecrets);
    }
}

export class KeyRollingResult {
	public KeyRollingConfirmation: any;
	public NewSecrets: any;

    constructor(keyRollingConfirmation, newSecrets) {
        this.KeyRollingConfirmation = keyRollingConfirmation;
        this.NewSecrets = newSecrets;
    }
}

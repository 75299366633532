import {RequestIdHelper} from './RequestIdHelper';
import {Data, Events, Message} from './Messages';

export class PongHelper
{
    static GeneratePongResponse(ping: Message<Data>)
    {
        return new Message<Data>(ping.Id, Events.Pong, null, true);
    }
}

export class PingHelper
{
    static GeneratePingRequest()
    {
        return new Message(RequestIdHelper.Id("ping"), Events.Ping, null, true);
    }
}

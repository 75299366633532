import {Data, Events, Message} from './Messages';
import {RequestIdHelper} from './RequestIdHelper';

export class TerminalStatusRequest
{
    ToMessage()
    {
        const data = {};

        return new Message(RequestIdHelper.Id("trmnl"), Events.TerminalStatusRequest, data, true);
    }
}

export interface TerminalStatusResponseData extends Data {
    battery_level: number
    charging: boolean
    status: string
}

export class TerminalStatusResponse
{
	public _m: Message<TerminalStatusResponseData>;

    constructor(m: Message<TerminalStatusResponseData>)
    {
        this._m = m;
    }
    GetStatus()
    {
        return this._m.Data.status;
    }
    GetBatteryLevel()
    {
        return parseInt(String(this._m.Data.battery_level),10);
    }
    IsCharging()
    {
        return !!this._m.Data.charging;
    }
}

export class TerminalBattery
{
	public BatteryLevel: number;

    constructor(m)
    {
        this.BatteryLevel = parseInt(m.Data.battery_level,10);
    }
}
